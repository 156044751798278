import {
  useCustomFields,
  useMemberships,
  useProfiles,
} from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { ICustomField, IMembership } from '@guider-global/shared-types';
import {
  Button,
  Checkbox,
  DropdownItemProps,
  FormMultiSelectInput,
  FormSelectInput,
  FormTextArea,
  Loading,
  MultiSelectAreaInputValue,
  SelectareaInputValue,
  Stack,
  TextareaInputValue,
  TextStack,
} from '@guider-global/ui';
import { useLocalization } from 'hooks';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { showAppAlert } from 'store/slices/appSlice';

type MembershipFormValues = {
  [key: string]:
    | TextareaInputValue
    | SelectareaInputValue
    | MultiSelectAreaInputValue;
};

export function ProgramMembershipForm() {
  // Params
  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State
  const [loading, setLoading] = useState<boolean>(false);
  const [published, setPublished] = useState<boolean>(false);

  // Organization
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);

  // Program
  const { programs, isLoadingPrograms } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
    options: {
      keepPreviousData: false,
    },
  });

  const program = programs.find(
    (program) => program.metadata.id.current === programSlug,
  );

  // Profile

  const { profile, isLoadingProfiles } = useProfiles({});

  // Memberships
  const {
    memberships,
    isLoadingMemberships,
    reqMemberships,
    isMutatingMemberships,
  } = useMemberships({});

  const membership = memberships?.find(
    (membership) =>
      membership?.programSlug === programSlug && membership?.role === role,
  );

  // Custom Fields

  const { reqCustomFields, isLoadingCustomFields, isMutatingCustomFields } =
    useCustomFields({});

  // Base Language
  const { baseLanguage } = useBaseLanguage({ localeCode });

  // Effects

  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm<MembershipFormValues>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (!membership || isLoadingMemberships || loading) return;

    setPublished(membership?.isPublished ?? false);

    const questions = membership?.programFields ?? [];

    const fields = Object.assign(
      {},
      ...(questions as ICustomField[]).map(
        ({ fieldSlug, fieldType, value }) => {
          if (fieldType === 'multi-select') {
            return {
              [fieldSlug]: {
                fieldType,
                value: value ?? [],
              },
            };
          } else {
            return {
              [fieldSlug]: {
                fieldType,
                value: value ?? '',
              },
            };
          }
        },
      ),
    );

    reset({
      ...fields,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership]);

  const onSubmit: SubmitHandler<MembershipFormValues> = async (
    data: MembershipFormValues,
  ) => {
    if (!profile || !program) return;
    setLoading(true);

    if (!membership) {
      let customFieldIds: string[] = [];
      const customFieldsPostData = Object.entries(data)
        .filter(([_, { fieldType, value }]) => {
          if (fieldType === 'multi-select') {
            return value.length !== 0;
          } else {
            return value !== '';
          }
        })
        .map(([fieldSlug, { fieldType, value }]) => {
          return {
            fieldSlug,
            organizationSlug,
            programSlug,
            fieldType,
            value,
            profileId: profile.id,
          };
        }) as ICustomField[];
      if (customFieldsPostData && customFieldsPostData?.length !== 0) {
        const customFieldsResponse = await reqCustomFields({
          method: 'POST',
          url: '/customfields',
          data: [...customFieldsPostData],
        });
        if (
          customFieldsResponse?.status === 'success' &&
          customFieldsResponse?.data
        ) {
          customFieldIds = customFieldsResponse?.data?.map((field) => field.id);
        } else {
          dispatch(
            showAppAlert({
              severity: 'error',
              message: customFieldsResponse?.message,
              timeout: 5000,
            }),
          );
        }
      }

      const membershipData: Partial<IMembership> = {
        role,
        organizationSlug,
        programSlug,
        profile: profile?.id,
        isPublished: false,
        programFields: customFieldIds ?? [],
        programVariationTypeSlug: program?.program_details?.program_variation,
      };

      const result = await reqMemberships({
        method: 'POST',
        url: '/memberships',
        data: membershipData,
      });

      if (result.status === 'success') {
        navigate('./goals');
      } else {
        dispatch(
          showAppAlert({
            severity: 'error',
            message: result?.message,
            timeout: 5000,
          }),
        );
      }
      setLoading(false);
    } else {
      const membershipResult = await reqMemberships({
        method: 'PATCH',
        url: `/memberships/${membership?.id}`,
        data: {
          isPublished: membership?.isPublished ?? false,
          programSlug,
          role,
        },
      });

      if (membershipResult?.status !== 'success') {
        dispatch(
          showAppAlert({
            severity: 'error',
            message: membershipResult?.message,
            timeout: 5000,
          }),
        );
      }

      let customFieldsPostData: ICustomField[] = [];

      const existingFields = membership?.programFields as ICustomField[];

      const updatedFieldsData = Object.values(data)
        .filter((field) => field?.name)
        .map(({ fieldType, value, name }) => {
          return {
            fieldSlug: name,
            organizationSlug,
            fieldType,
            value,
            profileId: profile.id,
          };
        });

      updatedFieldsData.forEach(async (field) => {
        const profileField = existingFields?.find(
          (f) => f?.fieldSlug === field?.fieldSlug,
        );

        if (profileField) {
          const fieldId = profileField?.id;
          const fieldType = profileField?.fieldType;
          const value = field?.value as string | string[];

          const result = await reqCustomFields({
            method: 'PATCH',
            url: `/customfields/${fieldId}`,
            data: {
              value,
              fieldType,
            },
          });

          if (result.status === 'error') {
            dispatch(
              showAppAlert({
                severity: 'error',
                message: result?.message,
                timeout: 5000,
              }),
            );
          }
        } else {
          customFieldsPostData?.push(field as ICustomField);
        }
      });

      if (customFieldsPostData?.length > 0) {
        const customFieldsPostResult = await reqCustomFields({
          method: 'POST',
          url: `/customfields`,
          data: [...customFieldsPostData],
        });

        if (
          customFieldsPostResult?.status === 'success' &&
          customFieldsPostResult?.data
        ) {
          const customFieldIds = customFieldsPostResult?.data?.map(
            (field) => field.id,
          );

          const membershipResult = await reqMemberships({
            method: 'PATCH',
            url: `/memberships/${membership?.id}`,
            data: {
              isPublished: published,
              programSlug,
              ...(customFieldIds && {
                programFields: [
                  ...(membership?.programFields as ICustomField[])?.map(
                    (field) => field?.id,
                  ),
                  ...customFieldIds,
                ],
              }),
            },
          });

          await reqMemberships({
            method: 'GET',
            url: '/memberships',
          });

          if (membershipResult?.status === 'success') {
            navigate('./goals');
          } else {
            dispatch(
              showAppAlert({
                severity: 'error',
                message: membershipResult?.message,
                timeout: 5000,
              }),
            );
          }
        } else {
          dispatch(
            showAppAlert({
              severity: 'error',
              message: customFieldsPostResult?.message,
              timeout: 5000,
            }),
          );
        }

        setLoading(false);
      } else {
        if (membership?.isPublished !== published) {
          const result = await reqMemberships({
            method: 'PATCH',
            url: `/memberships/${membership?.id}`,
            data: {
              isPublished: published,
              programSlug,
            },
          });

          await reqMemberships({
            method: 'GET',
            url: '/memberships',
          });

          if (result?.status === 'success') {
            navigate('./goals');
          } else {
            dispatch(
              showAppAlert({
                severity: 'error',
                message: result?.message,
                timeout: 5000,
              }),
            );
          }
        }
        navigate('./goals');
        setLoading(false);
      }
    }
  };

  const registrationQuestions =
    program?.registration?.registration_questions?.filter((question) =>
      [role, 'both'].includes(question.audience),
    ) ?? [];

  const title =
    program?.program_details?.program_type?.program_type_text?.common?.[
      role === 'guide' ? 'guide' : 'trainee'
    ]?.singular;

  const isLoading =
    isLoadingMemberships ||
    isLoadingCustomFields ||
    isLoadingProfiles ||
    isLoadingPrograms;

  if (!baseLanguage || !role) return <></>;

  if (isLoading && !loading) {
    return <Loading color="secondary" />;
  }

  return (
    <Stack
      direction={'column'}
      justifyContent={'flex-start'}
      width={{ xs: '90%', md: '480px' }}
      pt={{ xs: 4, md: 2 }}
    >
      <TextStack
        size={'xs'}
        heading={program?.metadata?.program_name}
        subtitles={[{ text: title, variant: 'h2' }]}
      />
      <Stack
        component={'form'}
        onSubmit={handleSubmit(onSubmit)}
        direction={'column'}
        gap={1}
      >
        {registrationQuestions.map((question) => {
          const fieldOptions: DropdownItemProps[] | undefined =
            question?.options?.map((option: any) => {
              return {
                key: option?.id?.current,
                value: option?.id?.current,
                label: option?.label,
              };
            });
          const roleText =
            role === 'guide' ? question?.guide : question?.trainee;
          if (question?.type === 'free-text') {
            return (
              <FormTextArea
                required={question?.required}
                key={question?._key}
                control={control}
                rows={
                  question?.free_text_config?.text_area_size === 'singleline'
                    ? 1
                    : 7
                }
                label={
                  roleText?.question_title + (question?.required ? '*' : '')
                }
                description={roleText?.question_description}
                labelSize="xs"
                placeholder={question?.text?.input_label}
                name={question?.id?.current}
                errorMessage={
                  question?.required_validation_error_text ??
                  baseLanguage?.globals?.errors?.required_field
                }
                tooltipText={question?.text?.input_assistive_text}
                min={question?.free_text_config?.minimum_length}
                minErrorText={
                  question?.free_text_config?.text
                    ?.minimum_length_not_met_validation_error
                }
                max={question?.free_text_config?.maximum_length}
                maxErrorText={
                  question?.free_text_config?.text
                    ?.maximum_length_exceeded_validation_error
                }
              />
            );
          }

          if (question?.type === 'select' && fieldOptions) {
            return (
              <FormSelectInput
                required={question?.required}
                key={question?._key}
                control={control}
                label={
                  roleText?.question_title + (question?.required ? '*' : '')
                }
                description={roleText?.question_description}
                name={question?.id?.current}
                options={fieldOptions}
                placeholder={question?.text?.input_label}
                labelSize="xs"
                errorMessage={baseLanguage?.globals?.errors?.required_field}
                tooltipText={question?.text?.input_assistive_text}
              />
            );
          }
          if (question?.type === 'multi-select' && fieldOptions) {
            return (
              <FormMultiSelectInput
                required={question?.required}
                key={question?._key}
                control={control}
                label={
                  roleText?.question_title + (question?.required ? '*' : '')
                }
                description={roleText?.question_description}
                name={question?.id?.current}
                options={fieldOptions}
                placeholder={question?.text?.input_label}
                labelSize="xs"
                errorMessage={baseLanguage?.globals?.errors?.required_field}
                tooltipText={question?.text?.input_assistive_text}
                max={question?.multi_select_config?.maximum_selections}
                min={question?.multi_select_config?.minimum_selections}
                minErrorMessage={
                  question?.free_text_config?.text
                    ?.minimum_length_not_met_validation_error
                }
                maxErrorMessage={
                  question?.free_text_config?.text
                    ?.maximum_length_exceeded_validation_error
                }
              />
            );
          }

          return undefined;
        })}
        {role === 'guide' &&
          membership &&
          membership?.requiresApproval === false && (
            <Checkbox
              isChecked={published}
              onChange={() => setPublished(!published)}
              heading={
                baseLanguage?.programs?.manage_guide_profile
                  ?.profile_published_boolean_label
              }
              subtitle={
                baseLanguage?.programs?.manage_guide_profile
                  ?.profile_published_boolean_description
              }
            />
          )}
        <Button
          loading={loading || isMutatingCustomFields || isMutatingMemberships}
          variant="contained"
          label={baseLanguage?.globals?.common?.continue_button_label}
          color="info"
          type="submit"
          disabled={!isValid}
        />
      </Stack>
    </Stack>
  );
}
